<script>
import { defineComponent, ref, watch, onMounted } from 'vue'
import gsap from 'gsap'

import Logo from '@/assets/svg/logo-main.svg'
import Ornament from '@/assets/svg/ornament.svg'

export default defineComponent({
  name: 'ThePreloader',
  components: {
    Logo,
    Ornament
  },
  props: {
    ornaments: { type: Boolean, default: false }
  },
  setup () {
    const visible = ref(true)
    let tl

    function onVisibleChange () {
      if (!tl) return

      tl.tweenTo(visible.value ? 'show' : 'hide', {
        duration: visible.value ? 0.5 : 1,
        onStart: () => {
          gsap.set('#app-loading', { autoAlpha: 1 })
        },
        onComplete: () => {
          gsap.set('#app-loading', { autoAlpha: visible.value ? 1 : 0 })
        }
      })
    }

    watch(visible, onVisibleChange, { immediate: true })

    onMounted(() => {
      const prop = (window.innerWidth > window.innerHeight) ? 'xPercent' : 'yPercent'
      tl = gsap.timeline({ paused: true })
      tl.addLabel('show')
      tl.fromTo('#app-loading .app-loading-content', { autoAlpha: 1 }, { autoAlpha: 0, duration: 0.8 })
      tl.fromTo('#app-loading .app-loading-bg', {
        [prop]: 0
      }, {
        [prop]: -100,
        duration: 0.8,
        ease: 'circ.inOut'
      })
      tl.addLabel('hide')
      tl.seek(visible.value ? 'show' : 'hide')
    })

    return {
      visible
    }
  }
})
</script>

<template>
  <div id="app-loading-container">
    <div id="app-loading">
      <div class="app-loading-bg"></div>
      <div class="app-loading-content">
        <Ornament v-if="ornaments" class="app-loading-ornament" />
        <Logo class="block w-full px-6 mx-auto my-8 lg:w-100" />
        <Ornament v-if="ornaments" class="app-loading-ornament" />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
#app-loading {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  color: $c-white;
  text-align: center;
}

.app-loading-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $c-black;
}

.app-loading-content {
  position: relative;
}

.app-loading-ornament {
  display: block;
  width: rem(20px);
  height: rem(20px);
  margin: 0 auto;

  &:last-child {
    transform: rotate(180deg);
  }
}
</style>
